import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Repos, { ReposTabs } from "../components/Repos"
import Footer from '../components/footer'
import { bdstatistics, apiURL, mqStatistics } from '../components/census'

export default () => {

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])
  
  return (
    <div>
      <div className="home-bg" style={{ background: `url('')`, height: '7.5rem'  }}>
        <Layout><SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" /></Layout>
      </div>
      <Container>
        <div className="repos-title-box">
          <span className="title-line"></span>
          <span className="title-repos">模块列表</span>
        </div>
        <ReposTabs />
        <StaticQuery
          query={graphql`
          {
            allStrapiRepos(sort: {fields: group_name, order: ASC}) {
              nodes {
                releases {
                  tag_name
                  create_time
                  id
                }
                group_name
                strapiId
                repo_name
                description
                id
              }
            }
          }
          `}
          render={data => <Repos repos={data.allStrapiRepos.nodes} />}></StaticQuery>
        </Container>
      <Footer style={{ marginTop: '7.5rem' }}></Footer>
    </div>
  )
}

